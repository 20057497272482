import Login from '@oberoninternal/travelbase-website/dist/components/pages/Login';
import { getGlobalProps } from '../utils/addGlobalQuery';
import { GetStaticProps } from 'next';
import React from 'react';
import styled from 'styled-components';

export default () => (
    <MyWrap>
        <Login />
    </MyWrap>
);

const MyWrap = styled.div`
    min-height: 100vh;
`;

export const getStaticProps: GetStaticProps = getGlobalProps;
